<template>
  <div
    class="csn-lsm"
    :class="{ 'csn-lsm-displayed': SHOWS_FORGOT_PASSWORD_SIDE_MENU }"
  >
    <div class="csn-lsm-header">
      <button @click="handleArrowClick" class="csn-lsm-arrow-back">
        <SlideArrow />
      </button>
      <NavbarLogoButton @click.native="handleCloseClick" />
      <button @click="handleCloseClick" class="csn-notification-menu-close-btn">
        <CloseIcon />
      </button>
    </div>
    <ForgotPassword />
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import {
  FORGOT_PASSWORD_SIDE_MENU,
  TOGGLE_FORGOT_PASSWORD_SIDE_MENU,
  SHOWS_FORGOT_PASSWORD_SIDE_MENU,
  Module,
  RouteName,
  Digit,
} from '@/constants'
import { navigateTo, requestTimeout, cancelTimeout } from '@/helpers'

export default {
  name: FORGOT_PASSWORD_SIDE_MENU,
  components: {
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
    NavbarLogoButton: () =>
      import('@/App/components/Navbar/components/NavbarLogoButton'),
    ForgotPassword: () => import('@/modals/ForgotPassword'),
  },
  data: () => ({
    openNextSideMenu: null,
  }),
  computed: {
    ...mapState(Module.MAIN, [SHOWS_FORGOT_PASSWORD_SIDE_MENU]),
  },
  methods: {
    ...mapMutations(Module.MAIN, [TOGGLE_FORGOT_PASSWORD_SIDE_MENU]),
    handleArrowClick() {
      this.TOGGLE_FORGOT_PASSWORD_SIDE_MENU(false)

      this.$nextTick(() => {
        this.openNextSideMenu = requestTimeout(() => {
          cancelTimeout(this.openNextSideMenu)
          this.openNextSideMenu = null
          navigateTo({ name: RouteName.LOGIN })
        }, Digit.TWO_HUNDRED)
      })
    },
    handleCloseClick() {
      this.TOGGLE_FORGOT_PASSWORD_SIDE_MENU(false)
      navigateTo({ name: RouteName.HOME })
    },
  },
}
</script>
